<template>
	<div class="m-name">
		{{mentee['name-first']}} {{mentee['name-last']}}
	</div>
	<div class="m-utility">
		<router-link class="button" :to="{ name: 'MenteeProfile', params: { id: mentee.id }}">
			<i class="fad fa-user-edit"></i>
		</router-link>
		<router-link class="button" :to="{ name: 'MenteeViewProfile', params: { id: mentee.id }}">
			<i class="fad fa-eye"></i>
		</router-link>
	</div>
	<div class="m-data">
		<ul v-if="mentee['sessions']">
			<li>{{mentee['sessions']}}</li>
		</ul>
		<ul class="contracts">
			<li v-for="(contract, index) in 2" :key="index">
				<i v-if="mentee['contracts'] && mentee['contracts'][index]" class="fad fa-file-contract fa-lg"></i>
			</li>
		</ul>
	</div>
</template>

<script>
import { computed } from 'vue'

export default {
	props: ['mentee'],
	setup(props) {
		// const snippet = computed(() => {
		// 	return props.mentee.body.substring(0, 100) + '...'
		// })

		return { }
	}
}
</script>

<style>
</style>
