<template>
	<NavBar />
	<div class="mentees">
		<h1>Mentees</h1>
		<div v-if="error">{{ error }}</div>
		<div class="sub-nav">
			<router-link :to="{ name: 'Mentee'}">
				<i class="fad fa-smile-plus fa-lg"></i> Add Mentee
			</router-link>
		</div>
		<div v-if="mentees.length" class="mentors-mentees">
			<MenteeList :mentees="mentees" />
		</div>
		<div v-else>
			<Spinner />
		</div>
	</div>
</template>

<script>
import { ref } from 'vue'
import getMentees from '../composables/getMentees'

// component imports
import NavBar from '../components/NavBar.vue'
import MenteeList from '../components/MenteeList.vue'
import Spinner from '../components/Spinner.vue'

export default {
	name: 'Mentees',
	components: { NavBar, MenteeList, Spinner },
	setup() { 
	const { mentees, error, load } = getMentees()

	load()

	return { mentees, error }
	}
}
</script>
