<template>
	<div class="home">
		<LoginForm v-if="!loggedIn" />
		<div v-else>
			<p class="msg">You are logged in, visit the <router-link :to="{ name: 'Admin'}">ADMIN</router-link></p>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue'
import LoginForm from '../components/LoginForm.vue'
import { projectAuth } from '../firebase/config'

export default {
	name: 'Home',
	components: { LoginForm },
	setup(){
		let 
			loggedIn = ref(false)

		let user = projectAuth.currentUser
		if(user){
			loggedIn = true
		}

		return {
			loggedIn
		}
	}
}
</script>
<style scoped>
.msg {
	text-align: center;
	margin-top: 1.5rem;
}
</style>