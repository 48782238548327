import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Mentor from '../views/Mentor.vue'
import Mentors from '../views/Mentors.vue'
import MentorProfile from '../views/MentorProfile.vue'
import MentorViewProfile from '../views/MentorViewProfile.vue'
import Mentee from '../views/Mentee.vue'
import Mentees from '../views/Mentees.vue'
import MenteeProfile from '../views/MenteeProfile.vue'
import MenteeViewProfile from '../views/MenteeViewProfile.vue'
import Admin from '../views/Admin.vue'
import NotFound from '../views/NotFound.vue'
import { projectAuth } from '../firebase/config'

// auth guards
const requireAuth = (to, from, next) => {
	let user = projectAuth.currentUser
	if (!user) {
		next({ name: 'Home' })
	} else {
		next()
	}
}


const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/mentor',
		name: 'Mentor',
		component: Mentor
	},
	{
		path: '/mentors',
		name: 'Mentors',
		component: Mentors,
		beforeEnter: requireAuth
	},
	{
		path: '/mentors/:id',
		name: 'MentorProfile',
		component: MentorProfile,
		props: true,
		beforeEnter: requireAuth
	},
	{
		path: '/mentors/view/:id',
		name: 'MentorViewProfile',
		component: MentorViewProfile,
		props: true,
		beforeEnter: requireAuth
	},
	{
		path: '/mentee',
		name: 'Mentee',
		component: Mentee
	},
	{
		path: '/mentees',
		name: 'Mentees',
		component: Mentees,
		beforeEnter: requireAuth
	},
	{
		path: '/mentees/:id',
		name: 'MenteeProfile',
		component: MenteeProfile,
		props: true,
		beforeEnter: requireAuth
	},
	{
		path: '/mentees/view/:id',
		name: 'MenteeViewProfile',
		component: MenteeViewProfile,
		props: true,
		beforeEnter: requireAuth
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin,
		beforeEnter: requireAuth
	},
	{
		path: '/:catchAll(.*)',
		name: NotFound,
		component: () => import('../views/NotFound.vue')
	}
	//{
	//	path: '/about',
	//	name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
	//	component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	//}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
