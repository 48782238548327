<template>
	<form @submit.prevent="handleSubmit">
		<fieldset>
			<legend>Login</legend>
			<div class="label-field">
				<label for="email">Email</label>
				<input id="email" type="email" required v-model="email">
			</div>
			<div class="label-field">
				<label for="password">Password</label>
				<input id="password" type="password" required v-model="password">
			</div>
			<div class="submit-group">
				<button type="submit" :disabled="loading">
					<span v-if="!loading">Login</span>
					<span v-else>Loading&hellip;</span>
				</button>
				<div class="error">{{ error }}</div>
			</div>
		</fieldset>
	</form>
</template>

<script>
import { ref } from 'vue'
import useLogin from '../composables/useLogin'
import { useRouter } from 'vue-router'

export default {
setup() {
	const email = ref('')
	const password = ref('')
	const { error, login } = useLogin()
	const router = useRouter()

	let loading = ref(false)

	const handleSubmit = async () => {
		loading.value = true;
		await login(email.value, password.value)
			if (!error.value) {
				router.push({ name: 'Admin' })
			} else {
				loading.value = false;
			}
		}

		return { email, password, handleSubmit, error, loading }
	}
}
</script>

<style scoped>
.error {
	color: #ff0000;
	font-weight: bold;
	margin-top: 1.5rem;
}
</style>