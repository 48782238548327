<template>
	<div class="spin"></div>
</template>

<style>
.spin {
	display: block;
	width: 42px;
	height: 42px;
	margin: 30px auto;
	border: 3px solid transparent;
	border-radius: 50%;
	border-top-color: #1cb2ca;
	animation: spin 1s ease infinite;
}

@keyframes spin {
	to { -webkit-transform: rotateZ(360deg);  }
}
</style>