<template>
	<div v-if="error">{{ error }}</div>
	<div class="go-back">
		<a @click="$router.go(-1)"><i class="fad fa-long-arrow-left fa-lg"></i> Go Back</a>
		<i class="fad fa-chalkboard-teacher fa-lg"></i>
	</div>
	<div v-if="mentor" class="mentor view-profile">
		<h1>{{ mentor['name-last']}}, {{ mentor['name-first']}}</h1>
		<ul class="main">
			<li>
				<a :href="'mailto:' + mentor['email']"><i class="fad fa-envelope"></i> {{mentor['email']}}</a>
			</li>
			<li>
				<i class="fad fa-book-user"></i> {{mentor['bio']}}
			</li>
			<li>
				<i class="fad fa-birthday-cake"></i> {{mentor['dob-month']}}/{{mentor['dob-day']}}
			</li>
			<li>
				<address>
					{{mentor['address']}}<br>
					{{mentor['city']}},{{mentor['state']}} {{mentor['zip']}}
				</address>
			</li>
			<li>
				<i class="fad fa-globe"></i> <a :href="mentor['website']" target="_blank">{{mentor['website']}}</a>
			</li>
			<li>
				<a :href="'https://instagram.com/' + mentor['instagram']" target="_blank"><i class="fad fa-at"></i>{{mentor['instagram']}}</a>
			</li>
			<li>
				<i class="fad fa-star-exclamation"></i> {{mentor['fact']}}
			</li>
			<li>
				<ul class="files">
					<li v-for="file in mentor['headshots']" :key="file">
						<img :src="'https://ucarecdn.com/' + file + '/-/resize/150x/-/quality/normal/'" />
					</li>
				</ul>
			</li>
			<li>
				<ul class="files">
					<li v-for="file in mentor['photos']" :key="file">
						<img :src="'https://ucarecdn.com/' + file + '/-/resize/150x/-/quality/normal/'" />
					</li>
				</ul>
			</li>
			<li>
				<ul class="files">
					<li v-for="file in mentor['resumes']" :key="file">
						<a target="_blank" :href="'https://ucarecdn.com/' + file + '/'"><i class="fad fa-file-user fa-6x"></i></a>
					</li>
				</ul>
			</li>
			<li>
				<i class="fad fa-calendar-alt"></i>
				<ul>
					<li v-for="(session,index) in mentor['sessions']" :key="index">{{session}}</li>
				</ul>
			</li>
			<li>
				<i class="fad fa-file-signature"></i>
				<ul>
					<li v-for="(contract,index) in mentor['contracts']" :key="index">{{contract}}</li>
				</ul>
			</li>
			<li>
				<i class="fad fa-sticky-note"></i> {{mentor['notes']}}
			</li>
		</ul>
	</div>
	<div v-else>
	<Spinner />
	</div>
</template>

<script>
import { ref } from 'vue'
import getMentor from '../composables/getMentor'
import { useRoute, useRouter } from 'vue-router'
import { projectFirestore, timestamp } from '../firebase/config'
import uploadcare from 'uploadcare-widget'

// component imports
import Spinner from '../components/Spinner.vue'

export default {
	props: ['id'],
	components: { Spinner },
	setup(props) {

		let 
			dobMonth = ref(),
			dobDay = ref(),
			headshots = ref([]),
			photos = ref([]),
			resumes = ref([]),
			loading = ref(false)

		const route = useRoute()
		const { error, mentor, load } = getMentor(route.params.id)
		const router = useRouter()

		load()

		return { error, mentor, loading }
	},
}
</script>
