<template>
	<div v-if="error">{{ error }}</div>
	<div class="go-back">
		<a @click="$router.go(-1)"><i class="fad fa-long-arrow-left fa-lg"></i> Go Back</a>
		<i class="fad fa-user-graduate fa-lg"></i>
	</div>
	<div v-if="mentee" class="mentee view-profile">
		<h1>{{ mentee['name-last']}}, {{ mentee['name-first']}}</h1>
		<ul class="main">
			<li>
				<a :href="'mailto:' + mentee['email']"><i class="fad fa-envelope"></i> {{mentee['email']}}</a>
			</li>
			<li>
				<i class="fad fa-book-user"></i> {{mentee['bio']}}
			</li>
			<li>
				<i class="fad fa-birthday-cake"></i> {{mentee['dob-month']}}/{{mentee['dob-day']}}
			</li>
			<li>
				<i class="fad fa-globe"></i> <a :href="mentee['website']" target="_blank">{{mentee['website']}}</a>
			</li>
			<li>
				<a :href="'https://instagram.com/' + mentee['instagram']" target="_blank"><i class="fad fa-at"></i>{{mentee['instagram']}}</a>
			</li>
			<li>
				<i class="fad fa-star-exclamation"></i> {{mentee['fact']}}
			</li>
			<li>
				<ul class="files">
					<li v-for="file in mentee['headshots']" :key="file">
						<img :src="'https://ucarecdn.com/' + file + '/-/resize/150x/-/quality/normal/'" />
					</li>
				</ul>
			</li>
			<li>
				<ul class="files">
					<li v-for="file in mentee['photos']" :key="file">
						<img :src="'https://ucarecdn.com/' + file + '/-/resize/150x/-/quality/normal/'" />
					</li>
				</ul>
			</li>
			<li>
				<ul class="files">
					<li v-for="file in mentee['resumes']" :key="file">
						<a target="_blank" :href="'https://ucarecdn.com/' + file + '/'"><i class="fad fa-file-user fa-6x"></i></a>
					</li>
				</ul>
			</li>
			<li>
				<i class="fad fa-calendar-alt"></i>
				<ul>
					<li>{{mentee['sessions']}}</li>
				</ul>
			</li>
			<li>
				<i class="fad fa-file-signature"></i>
				<ul>
					<li v-for="(contract,index) in mentee['contracts']" :key="index">{{contract}}</li>
				</ul>
			</li>
			<li>
				<i class="fad fa-sticky-note"></i> {{mentee['notes']}}
			</li>
		</ul>
	</div>
	<div v-else>
	<Spinner />
	</div>
</template>

<script>
import { ref } from 'vue'
import getMentee from '../composables/getMentee'
import { useRoute, useRouter } from 'vue-router'
import { projectFirestore, timestamp } from '../firebase/config'
import uploadcare from 'uploadcare-widget'

// component imports
import Spinner from '../components/Spinner.vue'

export default {
	props: ['id'],
	components: { Spinner },
	setup(props) {

		let 
			dobMonth = ref(),
			dobDay = ref(),
			files = ref([]),
			loading = ref(false)

		const route = useRoute()
		const { error, mentee, load } = getMentee(route.params.id)
		const router = useRouter()

		load()

		return { error, mentee, loading }
	},
}
</script>
