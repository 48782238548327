<template>
	<div class="m-name">
		{{mentor['name-first']}} {{mentor['name-last']}}
	</div>
	<div class="m-utility">
		<router-link class="button" :to="{ name: 'MentorProfile', params: { id: mentor.id }}">
			<i class="fad fa-user-edit"></i>
		</router-link>
		<router-link class="button" :to="{ name: 'MentorViewProfile', params: { id: mentor.id }}">
			<i class="fad fa-eye"></i>
		</router-link>
	</div>
	<div class="m-data">
		<ul v-if="mentor['sessions']" >
			<li v-for="(session, index) in mentor['sessions']" :key="index">{{session}}</li>
		</ul>
		<ul v-if="mentor['sessions'].length < 1">
			<li>Unassigned</li>
		</ul>
		<ul class="contracts">
			<li v-for="(contract, index) in 2" :key="index">
				<i v-if="mentor['contracts'] && mentor['contracts'][index]" class="fad fa-file-contract fa-lg"></i>
			</li>
		</ul>
	</div>
</template>

<script>
import { computed } from 'vue'

export default {
	props: ['mentor'],
	setup(props) {
		// const snippet = computed(() => {
		// 	return props.mentor.body.substring(0, 100) + '...'
		// })

		//return { snippet }
	}
}
</script>

<style>
</style>
