<template>
	<div v-if="error">{{ error }}</div>
	<div class="go-back">
		<a @click="$router.go(-1)"><i class="fad fa-long-arrow-left fa-lg"></i> Go Back</a>
		<i class="fad fa-user-graduate fa-lg"></i>
	</div>
	<div v-if="mentee" class="mentee">
		<h1>{{ mentee['name-last']}}, {{ mentee['name-first']}}</h1>
		<form v-on:submit.prevent="updateMentee">
			<fieldset>
				<legend>General</legend>
				<div class="label-field">
					<label for="first-name">First Name</label>
					<input id="first-name" type="text" required v-model="mentee['name-first']" />
				</div>
				<div class="label-field">
					<label for="last-name">Last Name</label>
					<input id="last-name" type="text" required v-model="mentee['name-last']">
				</div>
				<div class="label-field">
					<label for="email">Email</label>
					<input id="email" type="email" required v-model="mentee['email']">
				</div>
				<div class="label-field">
					<label for="bio">Bio</label>
					<textarea id="bio" rows="5" v-model="mentee['bio']"></textarea>
				</div>
				<div class="label-field">
					<label>DOB</label>
					<div class="field-group">
						<select id="month" v-model="mentee['dob-month']">
							<option>Month</option>
							<option v-for="m in 12" :key="m" :value="m">{{m}}</option>
						</select>
						<span> / </span>
						<!--  {{dobParse(mentee['dob'],'d')}} -->
						<select id="day" v-model="mentee['dob-day']">
							<option>Day</option>
							<option v-for="d in 31" :key="d" :value="d">{{d}}</option>
						</select>
					</div>
				</div>
			</fieldset>
			<fieldset>
				<legend>Social</legend>
				<div class="label-field">
					<label for="website">Website</label>
					<input id="website" type="text" placeholder="your-website-url.com" v-model="mentee['website']">
				</div>
				<div class="label-field">
					<label for="instagram">Instagram</label>
					<div class="field-group">
						<span>@</span>
						<input type="text" v-model="mentee['instagram']">
					</div>
				</div>
			</fieldset>
			<fieldset>
				<legend>Personal</legend>
				<div class="label-field">
					<label for="fact">Fun Fact</label>
					<input id="fact" type="text" required v-model="mentee['fact']">
				</div>
				<div>
					<ul class="all-files">
						<li v-for="file in mentee['headshots']" :key="file">
							<img :src="'https://ucarecdn.com/' + file + '/-/resize/210x/-/quality/lightest/'" />
							<ul class="file-utility">
								<li>
									<button @click="removeFile(file,'headshots')">
										<i class="fad fa-minus-circle"></i>
									</button>
								</li>
								<li>
									<a target="_blank" :href="'https://ucarecdn.com/' + file + '/'" class="button"><i class="fad fa-external-link fa-lg"></i></a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<div class="label-field file-u">
					<label for="file-headshot">Headshot</label>
					<input id="file-headshot" type="hidden" role="uploadcare-uploader" name="file-headshot" data-multiple="true" data-tabs="file url camera" />
				</div>

				<div>
					<ul class="all-files">
						<li v-for="file in mentee['photos']" :key="file">
							<img :src="'https://ucarecdn.com/' + file + '/-/resize/210x/-/quality/lightest/'" />
							<ul class="file-utility">
								<li>
									<button @click="removeFile(file,'photos')">
										<i class="fad fa-minus-circle"></i>
									</button>
								</li>
								<li>
									<a target="_blank" :href="'https://ucarecdn.com/' + file + '/'" class="button"><i class="fad fa-external-link fa-lg"></i></a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<div class="label-field file-u">
					<label for="file-photo">Lifestyle/Performance</label>
					<input id="file-photo" type="hidden" role="uploadcare-uploader" name="file-photo" data-multiple="true" data-tabs="file url camera" />
				</div>

				<div>
					<ul class="all-files">
						<li v-for="file in mentee['resumes']" :key="file">
							<i class="fad fa-file-user fa-6x"></i>
							<ul class="file-utility">
								<li>
									<button @click="removeFile(file,'resumes')">
										<i class="fad fa-minus-circle"></i>
									</button>
								</li>
								<li>
									<a target="_blank" :href="'https://ucarecdn.com/' + file + '/'" class="button"><i class="fad fa-external-link fa-lg"></i></a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<div class="label-field file-u">
					<label for="file-resume">Resume (optional)</label>
					<input id="file-resume" type="hidden" role="uploadcare-uploader" name="file-resume" data-multiple="true" data-tabs="file url" />
				</div>
			</fieldset>
			<fieldset>
				<legend>Session</legend>
				<ul>
					<li>
						<div class="radio-group">
							<input type="radio" id="2021-Spring" value="2021-Spring" v-model="mentee['sessions']" />
							<label for="2021-Spring">Spring 2021</label>
						</div>
					</li>
					<li>
						<div class="radio-group">
							<input type="radio" id="2021-Fall" value="2021-Fall" v-model="mentee['sessions']" />
							<label for="2021-Fall">Fall 2021</label>
						</div>
					</li>
					<li>
						<div class="radio-group">
							<input type="radio" id="2022-Spring" value="2022-Spring" v-model="mentee['sessions']" />
							<label for="2022-Spring">Spring 2022</label>
						</div>
					</li>
					<li>
						<div class="radio-group">
							<input type="radio" id="2022-Fall" value="2022-Fall" v-model="mentee['sessions']" />
							<label for="2022-Fall">Fall 2022</label>
						</div>
					</li>
					<li>
						<div class="radio-group">
							<input type="radio" id="2023-Spring" value="2023-Spring" v-model="mentee['sessions']" />
							<label for="2023-Spring">Spring 2023</label>
						</div>
					</li>
					<li>
						<div class="radio-group">
							<input type="radio" id="2023-Fall" value="2023-Fall" v-model="mentee['sessions']" />
							<label for="2023-Fall">Fall 2023</label>
						</div>
					</li>
					<li>
						<div class="radio-group">
							<input type="radio" id="2024-Spring" value="2024-Spring" v-model="mentee['sessions']" />
							<label for="2024-Spring">Spring 2024</label>
						</div>
					</li>
					<li>
						<div class="radio-group">
							<input type="radio" id="2024-Fall" value="2024-Fall" v-model="mentee['sessions']" />
							<label for="2024-Fall">Fall 2024</label>
						</div>
					</li>
				</ul>
			</fieldset>
			<fieldset>
				<legend>Contracts</legend>
				<ul>
					<li>
						<div class="checkbox-group">
							<input type="checkbox" id="individual" value="individual" v-model="mentee['contracts']" />
							<label for="individual">Individual</label>
						</div>
					</li>
					<li>
						<div class="checkbox-group">
							<input type="checkbox" id="joint" value="joint" v-model="mentee['contracts']" />
							<label for="joint">Joint</label>
						</div>
					</li>
				</ul>
			</fieldset>
			<fieldset>
				<legend>Internal Notes</legend>
				<div class="label-field">
					<label for="notes">Notes</label>
					<textarea id="notes" rows="5" v-model="mentee['notes']"></textarea>
				</div>
			</fieldset>
			<div class="submit-group">
				<button type="submit" :disabled="loading">
					<span v-if="!loading">Update</span>
					<span v-else>Loading&hellip;</span>
				</button>
			</div>
		</form>
	</div>
	<div v-else>
	<Spinner />
	</div>
</template>

<script>
import { ref, onUpdated } from 'vue'
import getMentee from '../composables/getMentee'
import { useRoute, useRouter } from 'vue-router'
import { projectFirestore, timestamp } from '../firebase/config'
import uploadcare from 'uploadcare-widget'

// component imports
import Spinner from '../components/Spinner.vue'

export default {
	props: ['id'],
	components: { Spinner },
	setup(props) {

		let 
			dobMonth = ref(),
			dobDay = ref(),
			headshots = ref([]),
			photos = ref([]),
			resumes = ref([]),
			loading = ref(false)

		const route = useRoute()
		const { error, mentee, load } = getMentee(route.params.id)
		const router = useRouter()

		function removeFile(which,arg2) {
			mentee.value[arg2] = mentee.value[arg2].filter(fileID => fileID !== which)
		}

		let updated_view = false

		function photoUpload(arg1,arg2){
			arg1.onUploadComplete(function (fileInfo) {
				fetch('https://upload.uploadcare.com/group/info/?pub_key=e72e43c77015616a3bb3&group_id=' + fileInfo.uuid)
				.then(res => {
					res.json().then(function(data) {
						for (const file of data.files){
							arg2.value.push(file.file_id)
						}
					});
				})
			});
		}

		onUpdated(() => {
			if(!updated_view){
				if(document.getElementsByClassName("uploadcare--widget_status_ready").length == 0){

					var widget_file_headshot = uploadcare.Widget('[id=file-headshot]')
						photoUpload(widget_file_headshot,headshots)

					var widget_file_photo = uploadcare.Widget('[id=file-photo]')
						photoUpload(widget_file_photo,photos)

					var widget_file_resume = uploadcare.Widget('[id=file-resume]')
						photoUpload(widget_file_resume,resumes)

				}
			}

			updated_view = true;

		})

		const updateMentee = async () => {

			loading.value = true

			mentee.value['headshots'] = mentee.value['headshots'] || []
			mentee.value['photos'] = mentee.value['photos'] || []
			mentee.value['resumes'] = mentee.value['resumes'] || []

			if(headshots.value.length > 0){
				headshots.value.forEach(function (item, index) {
					mentee.value['headshots'].push(item)
				})
			}

			if(photos.value.length > 0){
				photos.value.forEach(function (item, index) {
					mentee.value['photos'].push(item)
				})
			}

			if(resumes.value.length > 0){
				resumes.value.forEach(function (item, index) {
					mentee.value['resumes'].push(item)
				})
			}

			let menteeData = {
				"name-first": mentee.value['name-first'] || '',
				"name-last": mentee.value['name-last'] || '',
				email: mentee.value['email'] || '',
				bio: mentee.value['bio'] || '',
				"dob-month": mentee.value['dob-month'] || '',
				"dob-day": mentee.value['dob-day'] || '',
				website: mentee.value['website'] || '',
				instagram: mentee.value['instagram'] || '',
				fact: mentee.value['fact'] || '',
				headshots: mentee.value['headshots'],
				photos: mentee.value['photos'],
				resumes: mentee.value['resumes'],
				sessions: mentee.value['sessions'] || '',
				contracts: mentee.value['contracts'] || [],
				notes: mentee.value['notes'] || '',
				"created-at": mentee.value['created-at'] || '',
				"updated-at": timestamp()
			}

			await projectFirestore.collection('mentees').doc(props.id).update(menteeData).then(() => {
				alert('Form Updated 😀');
				loading.value = false
				headshots.value = false
				photos.value = false
				resumes.value = false
			}).catch(err => {
				console.log(err)
			})

		}

		load()

		return { error, mentee, updateMentee, removeFile, onUpdated, loading }
	},
}
</script>
