import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const getMentee = (id) => {

	const mentee = ref(null)
	const error = ref(null)

	const load = async () => {
		try {
			let res = await projectFirestore.collection('mentees').doc(id).get()

			if (!res.exists) {
				throw Error('That mentee does not exist')
			}
			mentee.value = { ...res.data(), id: res.id }
			mentee.value['sessions'] = mentee.value['sessions'] || ''
			mentee.value['contracts'] = mentee.value['contracts'] || []

			}
		catch(err) {
			error.value = err.message
		}
	}

	return { mentee, error, load } 
}

export default getMentee