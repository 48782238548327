<template>
	<NavBar />
	<div class="mentors">
		<h1>Mentors</h1>
		<div v-if="error">{{ error }}</div>
		<div class="sub-nav">
			<router-link :to="{ name: 'Mentor'}">
				<i class="fad fa-smile-plus fa-lg"></i> Add Mentor
			</router-link>
		</div>
		<div v-if="mentors.length" class="mentors-mentees">
			<MentorList :mentors="mentors" />
		</div>
		<div v-else>
			<Spinner />
		</div>
	</div>
</template>

<script>
import { ref } from 'vue'
import getMentors from '../composables/getMentors'

// component imports
import NavBar from '../components/NavBar.vue'
import MentorList from '../components/MentorList.vue'
import Spinner from '../components/Spinner.vue'

export default {
	name: 'Mentors',
	components: { NavBar, MentorList, Spinner },
	setup() { 
		const { mentors, error, load } = getMentors()

		load()

		return { mentors, error }
	}
}
</script>
