<template>
<div>
	<h1>Mentee</h1>
	<form v-on:submit.prevent="submitApp">
		<fieldset>
			<legend>General</legend>
			<div class="label-field">
				<label for="first-name">First Name</label>
				<input id="first-name" type="text" required v-model="firstName" />
			</div>
			<div class="label-field">
				<label for="last-name">Last Name</label>
				<input id="last-name" type="text" required v-model="lastName">
			</div>
			<div class="label-field">
				<label for="email">Email</label>
				<input id="email" type="email" required v-model="email">
			</div>
			<div class="label-field">
				<label for="bio">Bio</label>
				<textarea id="bio" rows="5" v-model="bio"></textarea>
			</div>
			<div class="label-field">
				<label>DOB</label>
				<div class="field-group">
					<!--  @change="dobMonthChange()" -->
					<select id="month" v-model="dobMonth">
						<option>Month</option>
						<option v-for="m in 12" :key="m" :value="m">{{m}}</option>
					</select>
					<span> / </span>
					<select id="day" v-model="dobDay">
						<option>Day</option>
						<option v-for="d in 31" :key="d" :value="d">{{d}}</option>
					</select>
				</div>
			</div>
		</fieldset>
		<fieldset>
			<legend>Social</legend>
			<div class="label-field">
				<label for="website">Website</label>
				<input id="website" type="text" placeholder="your-website-url.com" v-model="website">
			</div>
			<div class="label-field">
				<label for="instagram">Instagram</label>
				<div class="field-group">
					<span>@</span>
					<input type="text" v-model="instagram">
				</div>
			</div>
		</fieldset>
		<fieldset>
			<legend>Personal</legend>
			<div class="label-field">
				<label for="fact">Fun Fact</label>
				<input id="fact" type="text" required v-model="fact">
			</div>
			<div class="label-field">
				<label for="file-headshot">Headshot</label>
				<input id="file-headshot" type="hidden" role="uploadcare-uploader" name="file-headshot" data-multiple="true" data-tabs="file url camera" />
			</div>
			<div class="label-field">
				<label for="file-photo">Lifestyle/Performance</label>
				<input id="file-photo" type="hidden" role="uploadcare-uploader" name="file-photo" data-multiple="true" data-tabs="file url camera" />
			</div>
			<div class="label-field">
				<label for="file-resume">Resume (optional)</label>
				<input id="file-resume" type="hidden" role="uploadcare-uploader" name="file-resume" data-multiple="true" data-tabs="file url" />
			</div>
		</fieldset>
		<div class="submit-group">
			<p class="agree">By clicking the button below, I consent to submitting and having my information stored.</p>
			<button type="submit" :disabled="loading">
				<span v-if="!loading">Submit</span>
				<span v-else>Loading&hellip;</span>
			</button>
		</div>
	</form>
</div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { projectFirestore, timestamp } from '../firebase/config'
import uploadcare from 'uploadcare-widget'

export default {
	setup() {

		let 
			firstName = ref(''),
			lastName = ref(''),
			email = ref(''),
			bio = ref(''),
			dobMonth = ref('Month'),
			dobDay = ref('Day'),
			website = ref(''),
			instagram = ref(''),
			fact = ref(''),
			headshots = ref([]),
			photos = ref([]),
			resumes = ref([]),
			sessions = ref(''),
			loading = ref(false)

		onMounted(() => {

			function photoUpload(arg1,arg2){
				arg1.onUploadComplete(function (fileInfo) {
					fetch('https://upload.uploadcare.com/group/info/?pub_key=e72e43c77015616a3bb3&group_id=' + fileInfo.uuid)
					.then(res => {
						res.json().then(function(data) {
							for (const file of data.files){
								arg2.value.push(file.file_id)
							}
						});
					})
				});
			}

			var widget_file_headshot = uploadcare.Widget('[id=file-headshot]')
				photoUpload(widget_file_headshot,headshots)

			var widget_file_photo = uploadcare.Widget('[id=file-photo]')
				photoUpload(widget_file_photo,photos)

			var widget_file_resume = uploadcare.Widget('[id=file-resume]')
				photoUpload(widget_file_resume,resumes)

		})

		let submitApp = async () => {

			loading.value = true;

			let menteeData = {
				"name-first": firstName.value,
				"name-last": lastName.value,
				email: email.value,
				bio: bio.value,
				"dob-month": dobMonth.value,
				"dob-day": dobDay.value,
				website: website.value,
				instagram: instagram.value,
				fact: fact.value,
				headshots: headshots.value,
				photos: photos.value,
				resumes: resumes.value,
				sessions: sessions.value,
				"created-at": timestamp()
			}

			try {
				await projectFirestore.collection('mentees').add(menteeData);

				alert('Form Submitted 😀');

				var widget_hs = uploadcare.Widget('[id=file-headshot]')
					widget_hs.value(null)
				var widget_ph = uploadcare.Widget('[id=file-photo]')
					widget_ph.value(null)
				var widget_re = uploadcare.Widget('[id=file-resume]')
					widget_re.value(null)

				loading.value = false;
				firstName.value = ''
				lastName.value = ''
				email.value = ''
				bio.value = ''
				dobMonth.value = 'Month'
				dobDay.value = 'Day'
				website.value = ''
				instagram.value = ''
				fact.value = ''
				headshots.value = ''
				photos.value = ''
				resumes.value = ''
				sessions.value = ''
			} catch(err) {
				console.log(err.message);
			} finally {
				console.log('done')
			}

		}

		return { 
			firstName,
			lastName,
			email,
			bio,
			dobMonth,
			dobDay,
			website,
			instagram,
			fact,
			headshots,
			photos,
			resumes,
			sessions,
			loading,
			submitApp
		}

	}
}
</script>
