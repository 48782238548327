import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
	apiKey: "AIzaSyBWbsv1R-_q8ZFH3pBcVKhjiGbJRf-LaTE",
	authDomain: "mentee-mentor-7dd96.firebaseapp.com",
	databaseURL: "https://mentee-mentor-7dd96-default-rtdb.firebaseio.com",
	projectId: "mentee-mentor-7dd96",
	storageBucket: "mentee-mentor-7dd96.appspot.com",
	messagingSenderId: "707407395432",
	appId: "1:707407395432:web:0781f1f6dbe55f9c4f59dd"
};

firebase.initializeApp(firebaseConfig)

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { 
	projectAuth, 
	projectFirestore, 
	timestamp
}