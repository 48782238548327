import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const getMentors = () => {
	const mentors = ref([])
	const error = ref(null)

	const load = async () => {
		try {
			const res = await projectFirestore.collection('mentors')
			.orderBy('created-at', 'desc')
			.onSnapshot(snap => {
				let docs = snap.docs.map(doc => {
					return { ...doc.data(), id: doc.id }
				})
				mentors.value = docs
			})

		}
		catch (err){
			error.value = err.message
			console.log(error.value)
		}
	}

	return { mentors, error, load }
}

export default getMentors