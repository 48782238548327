<template>
	<nav>
		<ul>
			<li>
				<router-link :to="{ name: 'Mentors'}">
					<i class="fad fa-chalkboard-teacher"></i> Mentors
				</router-link>
			</li>
			<li>
				<router-link :to="{ name: 'Mentees'}">
					<i class="fad fa-user-graduate"></i> Mentees
				</router-link>
			</li>
			<li>
				<button @click="handleClick">
					<i class="fad fa-sign-out-alt fa-2x"></i>
				</button>
			</li>
		</ul>
	</nav>
</template>
<script>
import useLogout from '../composables/useLogout'
import { useRouter } from 'vue-router'

export default {
setup() {
	const { logout, error } = useLogout()
	const router = useRouter()

	const handleClick = async () => {
		await logout()
		if (!error.value) {
			router.push({ name: 'Home' })
		}
	}

	return { handleClick }
	}
}
</script>
<style scoped>
nav ul {
	display: flex;
	align-items: center;
}

	nav li {
		margin-right: 15px;
	}

		nav li:last-child {
			margin-right: 0;
			margin-left: auto;
		}

			nav a {
				color: #1cb2ca;
				font-size: 1.8rem;
				text-decoration: none;
				text-transform: uppercase;
			}

				nav a.router-link-active {
					font-weight: bold;
				}

			nav button {
				border: 0 none;
				min-height: 39px;
				padding-right: 3px;
				padding-left: 12px;
			}
</style>