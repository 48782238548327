import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'
import uploadcare from 'uploadcare-widget'

const getMentor = (id) => {

	const mentor = ref(null)
	const error = ref(null)

	const load = async () => {
		try {
			let res = await projectFirestore.collection('mentors').doc(id).get()
			// console.log(res)
			// console.log(res.data)

			if (!res.exists) {
				throw Error('That mentor does not exist')
			}
			mentor.value = { ...res.data(), id: res.id }
			mentor.value['sessions'] = mentor.value['sessions'] || []
			mentor.value['contracts'] = mentor.value['contracts'] || []

			}
		catch(err) {
			error.value = err.message
		}
	}

	return { mentor, error, load } 
}

export default getMentor